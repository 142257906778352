<template>
	<div class="wrapper">
		<my-section-component class="" title="お知らせ" icon="">
			<ul v-if="informations.length > 0" class="informations">
				<li v-for="(row, index) in informations" :key="row.id" @click="onInformationClick(index)">
					<div class="informations-left">
						<p class="informations-date">{{ row.created }}</p>
						<div class="informations-title">
							<span v-if="row.is_important" class="informations-important"><i class="fas fa-exclamation-circle"></i></span>
							<p>{{ row.title }}</p>
						</div>
					</div>

					<div class="informations-right">
						<i class="fas fa-chevron-right"></i>
					</div>
				</li>
			</ul>
			<div v-else class="no-information">お知らせはありません</div>
		</my-section-component>

		<p class="order_notice"> ※1回のご注文につき、送料は一律1,500円(税抜)となります。</p>

		<div class="order_header">
			<p class="order_header-title">オーダー</p>
			<!--
			<button class="button is-success">履歴からオーダー</button>
			-->
		</div>
		<div class="main">
			<b-tabs type="is-toggle" v-model="activeTab" expanded @input="onTabClick">
				<b-tab-item label="ブランド別" icon="">
					<div class="tab-wrapper">
						<ul class="brands">
							<li v-for="row in brands" :key="row.id">
								<div class="menu-button-wrapper">
									<a href="" class="menu-button" @click.prevent="$router.push({ name: 'order', params: { type: $consts.ORDER_TYPE_BRAND, id: row.id }})">
										<img class="brands-image preview" :src="row.image">
									</a>
								</div>
								<p class="brands-title">{{ row.name }}</p>
							</li>
							<li v-if="brands.length % 2 === 1"></li>
						</ul>
					</div>
				</b-tab-item>

				<b-tab-item label="カテゴリー別" icon="">
					<div class="tab-wrapper">
						<ul class="categories">
							<li v-for="row in categories" :key="row.id">
								<div class="menu-button-wrapper">
									<a href="" class="menu-button" @click.prevent="$router.push({ name: 'order', params: { type: $consts.ORDER_TYPE_CATEGORY, id: row.id }})">{{ row.name }}</a>
								</div>
							</li>
							<li v-if="categories.length % 2 === 1"></li>
						</ul>
					</div>
				</b-tab-item>
			</b-tabs>
		</div>

		<b-modal :active.sync="isInformationModalActive" has-modal-card>
			<div class="modal-card">
				<header class="modal-card-head">
					<div class="modal-card-title">
						<p class="mb-10">{{ infoDetail.date }}</p>
						<i v-if="infoDetail.is_important" class="fas fa-exclamation-circle"></i>
						{{ infoDetail.title }}
					</div>
				</header>

				<section class="modal-card-body">
					<img class="preview mb-20" v-if="infoDetail.image" :src="infoDetail.image" style="max-width: 200px;">
					<div class="word-wrap" v-html="$utils.hyperlink(infoDetail.body)"></div>
				</section>

				<footer class="modal-card-foot">
					<b-button @click="isInformationModalActive = false">閉じる</b-button>
				</footer>
			</div>
		</b-modal>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_home.scss';
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import MySectionComponent from '@/components/MySectionComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'

export default {
	name: 'Home',
	components: {
		MySectionComponent
	},
	data () {
		return {
			informations: [],
			isInformationModalActive: false,
			infoDetail: {
				title: '',
				body: '',
				image: '',
				date: '',
				is_important: false,
			},
			activeTab: 0,
			brands: [],
			categories: [],
			unsubscribe: null,
		}
	},
	computed: {
	},
	created() {
		this.activeTab = this.$store.state.activeTab;
	},
	befereDestroy() {
		this.unsubscribe();
	},
	beforeRouteLeave (to, from, next) {
		this.unsubscribe();
		next();
	},
	mounted () {
		this.unsubscribe = this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'changeStore') this.init();
		});
		this.init();
	},
	methods: {
		onTabClick: function() {
			this.$store.commit('basic', {
				key: 'activeTab',
				value: this.activeTab
			});
		},
		onInformationClick: function(index) {
			this.infoDetail.title = this.informations[index].title;
			this.infoDetail.body = this.informations[index].body;
			this.infoDetail.image = this.informations[index].image;
			this.infoDetail.is_important = this.informations[index].is_important;
			this.infoDetail.date = this.informations[index].created;
			this.isInformationModalActive = true;
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let ep = 'homes';
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.informations = res.data.informations;
				this.brands = res.data.brands;
				this.categories = res.data.categories;

				this.$store.state.brands = res.data.brands;
				this.$store.state.categories = res.data.categories;

			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
	},
}
</script>
