<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="基本情報" icon="">
				<b-field label="名前"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'name') }"
					:message="errors.name">
					<b-input type="text" v-model="name" />
				</b-field>

				<b-field label="フリガナ"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'nameKana') }"
					:message="errors.nameKana">
					<b-input type="text" v-model="nameKana" />
				</b-field>

				<b-field label="メールアドレス"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'email') }"
					:message="errors.email">
					<b-input type="text" v-model="email"/>
				</b-field>

				<b-field label="権限"
					horizontal
					custom-class="require"
					>
					<radio-picker :options="userPermissions" v-model="userPermissionId"></radio-picker>
				</b-field>

				<b-field horizontal class="form-buttons">
					<b-field grouped>
						<div class="control">
							<b-button @click="$router.go(-1)">戻る</b-button>
						</div>
						<div class="control">
							<b-button type="is-primary" :loading="isLoading" @click="onSubmitClick">{{ !id ? '登録' : '更新' }}</b-button>
						</div>
						<div v-if="id" class="control is-hidden-mobile">
							<b-button type="is-warning" class="password-reset" :loading="isPasswordResetLoading" @click="onPasswordResetClick">パスワードリセット</b-button>
						</div>
					</b-field>
				</b-field>

				<b-field horizontal class="form-buttons is-hidden-desktop is-hidden-tablet">
					<b-field grouped>
						<div v-if="id" class="control">
							<b-button type="is-warning" class="password-reset" :loading="isPasswordResetLoading" @click="onPasswordResetClick">パスワードリセット</b-button>
						</div>
					</b-field>
				</b-field>
			</card-component>
		</section>
	</div>
</template>

<style lang="scss" scoped>
.password-reset {
	width: 200px;
}
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import RadioPicker from '@/components/RadioPicker'

export default {
	name: 'AccountEdit',
	components: {
		RadioPicker,
		CardComponent,
		TitleBar,
	},
	data () {
		return {
			id: '',
			name: '',
			nameKana: '',
			email: '',
			userPermissionId: null,
			errors: {
				name: '',
				nameKana: '',
				email: '',
			},
			isLoading: false,
			isPasswordResetLoading: false,
			userPermissions: null,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		}
	},
	mounted() {
		this.init();
	},
	methods: {
		onPasswordResetClick: async function() {
			if (!window.confirm('パスワードをリセットします。よろしいですか？')) return false;

			this.isPasswordResetLoading = true;

			let data = {
				"id": this.id,
			};
			this.$utils.log(data);

			let ep = 'users/reset-password';
			this.$utils.log(ep);
			
			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('パスワードをリセットしてメールを送信しました');
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isPasswordResetLoading = false;
			}
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let params = this.$route.params.accountId ? '/' + this.$route.params.accountId : '';
			let ep = 'users/screen' + params;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.id = res.data.id;
				this.name = res.data.name;
				this.nameKana = res.data.name_kana;
				this.email = res.data.email;
				this.userPermissionId  = res.data.user_permission_id;
				this.userPermissions = res.data.user_permissions;
				this.prefectures = res.data.prefectures;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onSubmitClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isLoading = true;
			let data = {
				"id": this.id,
				"name": this.name,
				"name_kana": this.nameKana,
				"email": this.email,
				"user_permission_id": this.userPermissionId,
			};

			this.$utils.log(data);

			try {
				let ep = 'users';

				if (!this.id) {
					await this.$api.post(ep, data, true);
				} else {
					await this.$api.put(ep + '/' + this.id, data, true);
					this.$utils.toastSuccess('データを更新しました');
				}
				this.$router.push({ name: 'account' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isLoading = false;
			}
		},
	}
}
</script>
